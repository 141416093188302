<template>
  <validation-observer ref="simpleRules">
    <b-card-code title="Form Add User">
      <b-row class="match-height">
        <b-col md="12">

          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <b-form-input
                id="Name"
                v-model="Name"
                placeholder="Input Name"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="NIK"
            rules="required||numeric"
          >
            <b-form-group
              label="NIK"
              label-for="NIK"
            >
              <b-form-input
                id="NIK"
                v-model="NIK"
                placeholder="Input NIK"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-group
              label="Address"
              label-for="Address"
            >
              <b-form-input
                id="Address"
                v-model="Address"
                placeholder="Input Address"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Latest Education"
            rules="required"
          >
            <b-form-group
              label="Latest Education"
              label-for="Latest Education"
            >
              <b-form-input
                id="Latest Education"
                v-model="Education"
                placeholder="Input Latest Education"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Role"
            rules="required"
          >
            <b-form-group
              label="Role"
              label-for="Role"
            >
              <v-select
                v-model="selectedRole"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                placeholder="Select Role"
                :options="optionsKeputusan"
              />
            </b-form-group>

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Join Date"
            rules="required"
          >
            <b-form-group
              label="Join Date"
              label-for="Join Date"
            >
              <b-form-datepicker
                id="date-datepicker"
                v-model="JoinDate"
                class="mb-1"
              />

            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Jobdesc"
            rules="required"
          >
            <b-form-group
              label="Jobdesc"
              label-for="Jobdesc"
            >
              <b-form-input
                id="Jobdesc"
                v-model="Jobdesc"
                placeholder="Input Jobdesc"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Position"
            rules="required"
          >
            <b-form-group
              label="Position"
              label-for="Position"
            >
              <b-form-input
                id="Position"
                v-model="Position"
                placeholder="Input Position"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div
            v-if="!togglePhoto"
            class="photo mt-2"
          >
            <span> Photo </span>
            <div>
              <img :src="`https://api.arginipratamaputra.com${Photo}`">
            </div>
            <span
              v-show="this.$route.name === 'detailUser' && isAdmin"
              class="link"
              @click="togglePhoto = !togglePhoto"
            > Change Photo </span>
          </div>

          <div v-else>
            <b-form-group
              label="Photo"
              label-for="Photo"
            >
              <!-- Styled -->
              <b-form-file
                id="Photo"
                ref="file"
                v-model="file"
                type="file"
                placeholder="Input Photo"
                drop-placeholder="Drop file here..."
                multiple="multiple"
                @change="fileChange"
              />
              <span
                v-show="this.$route.name === 'detailUser'"
                class="link"
                @click="togglePhoto = !togglePhoto"
              > View Photo </span>
            </b-form-group>
          </div>

        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Area"
            class="mb-2"
            rules="required"
          >
            <b-form-group
              label="Area"
              label-for="Area"
            >
              <v-select
                v-model="selectedArea"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                multiple
                placeholder="Select Area"
                :options="optionsArea"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <div
            v-if="!toggleDoc"
            class="photo"
          >
            <span> Document </span>
            <br>
            <b-button
              v-show="$route.name === 'detailUser'"
              variant="outline-primary"
              class="bg-gradient-primary mt-1"
              type="submit"
              size="sm"
              @click="go(`https://api.arginipratamaputra.com${docName}`)"
            >
              <span class="align-middle">
                Download
              </span>
            </b-button>

            <span
              v-show="this.$route.name === 'detailUser' && isAdmin"
              class="link"
              @click="toggleDoc = !toggleDoc"
            > Change Document </span>
          </div>
          <div v-else>
            <b-form-group
              label="Document"
              label-for="Document"
            >
              <!-- Styled -->
              <b-form-file
                id="Document"
                ref="doc"
                type="file"
                placeholder="Input File Document"
                drop-placeholder="Drop file here..."
                multiple="multiple"
                @change="fileChangeDoc"
              />
            </b-form-group>
            <span
              v-show="this.$route.name === 'detailUser'"
              class="link"
              @click="toggleDoc = !toggleDoc"
            > View Document </span>
          </div>

          <validation-provider
            #default="{ errors }"
            name="Status"
            class="mt-2"
            rules="required"
          >
            <p style="margin-bottom:5px">
              Status
            </p>
            <b-form-checkbox
              v-model="Status"
              name="check-button"
              switch
              inline
            >
              Inactice/Active
            </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
    </b-card-code>

    <b-card-code
      v-show="isAdmin"
      title="Login Information"
    >
      <b-row class="match-height">
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required||email"
          >
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="Email"
                placeholder="Input Email"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Username"
            rules="required"
          >
            <b-form-group
              label="Username"
              label-for="Username"
            >
              <b-form-input
                id="Username"
                v-model="Username"
                placeholder="Input Username"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-show="this.$route.name !== 'detailUser'"
        class="match-height"
      >
        <b-col md="6">
          <validation-provider
            #default="{ errors }"
            name="Password"
          >
            <b-form-group
              label="Password"
              label-for="Password"
            >
              <b-form-input
                id="Password"
                v-model="Password"
                placeholder="Input Password"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-button
        v-show="$route.name === 'detailUser'"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        :disabled="invalid"
        @click.prevent="validationEdit"
      >
        <span class="align-middle">
          Save
        </span>
      </b-button>
      <b-button
        v-show="$route.name !== 'detailUser'"
        variant="outline-primary"
        class="bg-gradient-primary mt-2"
        type="submit"
        @click.prevent="validationForm"
      >
        <span class="align-middle">
          Add
        </span>
      </b-button>
    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BRow,
  BCol,
  BFormFile,
  BFormTags,
  BFormCheckboxGroup,
  BFormTextarea,
  BFormDatepicker,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, numeric } from '@validations'
import axios from '@axios'
import dayjs from 'dayjs'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import store from '@/store/index'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    email,
    numeric,
    BFormFile,
    BRow,
    BCol,
    BCardCode,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BFormTags,
    BFormCheckboxGroup,
    BFormTextarea,
    ToastificationContent,
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
  },
  data() {
    return {
      Name: '',
      NIK: '',
      Address: '',
      Education: '',
      selectedRole: [],
      JoinDate: '',
      Jobdesc: '',
      selectedArea: [],
      optionsArea: [],
      Position: '',
      Username: '',
      Email: '',
      isAdmin: false,
      togglePhoto: false,
      toggleDoc: false,
      Password: '',
      file: [],
      Photo: '',
      fileName: '',
      image: [],
      Status: true,
      docName: '',
      optionsKeputusan: [
        {
          value: '1',
          text: 'Admin',
        },
        {
          value: '2',
          text: 'Employee',
        },
        {
          value: '3',
          text: 'External',
        },
      ],
    }
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    // console.warn(this.$route.name)
    this.getArea()
    if (this.$route.name === 'detailUser') {
      this.getUserDetail()
    } else {
      this.togglePhoto = true
      this.toggleDoc = true
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    validationEdit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.edit()
        }
      })
    },

    async fileChange(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.fileName = data
    },

    async fileChangeDoc(e) {
      const formData = new FormData()
      e.target.files.forEach(file => {
        formData.append('foto', file)
      })
      const { data } = await axios.post('/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      this.docName = data
    },

    go(val) {
      window.location.href = val
    },

    async getUserDetail() {
      const { data } = await axios.get('user', {
        params: {
          id: this.$route.query.id,
        },
      })
      this.Name = data.data[0].nama
      this.NIK = data.data[0].nik
      this.Address = data.data[0].alamat
      this.Education = data.data[0].pendidikan
      let role = ''
      if (data.data[0].role === '1') {
        role = 'Admin'
      } else if (data.data[0].role === '2') {
        role = 'Employee'
      } else {
        role = 'External'
      }
      this.selectedRole = {
        value: data.data[0].role,
        text: role,
      }
      this.Jobdesc = data.data[0].jobdesk
      this.selectedArea = data.data[0].area.map(e => ({
        value: e.id,
        text: e.area,
      }))
      this.Photo = data.data[0].foto
      this.JoinDate = data.data[0].tanggal_gabung
      this.Position = data.data[0].jabatan
      this.Username = data.data[0].username
      this.Email = data.data[0].email
      this.fileName = data.data[0].foto
      this.docName = data.data[0].doc
      // this.Password = data.data[0].
    },

    async add() {
      // const path = this.$route.name === 'detailUser' ? '/user/update' : '/user'
      await axios
        .post('user', {
          nik: this.NIK,
          name: this.Name,
          study: this.Education,
          address: this.Address,
          jobdesk: this.Jobdesc,
          area_id: this.selectedArea.map(e => e.value),
          username: this.Username,
          password: this.Password,
          email: this.Email,
          foto: this.fileName,
          doc: this.docName,
          join_date: dayjs(this.JoinDate).format('YYYY-MM-DD'),
          role: this.selectedRole.value,
          status: this.Status === true ? 'ACTIVE' : 'INACTIVE',
          jabatan: this.Position,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getArea() {
      const { data } = await axios.get('/area')
      this.optionsArea = data.data.map(e => ({
        value: e.id,
        text: e.area,
      }))
    },

    async edit() {
      // const path = this.$route.name === 'detailUser' ? '/user/update' : '/user'
      await axios
        .post('/user/update', {
          id: this.$route.query.id,
          nik: this.NIK,
          name: this.Name,
          study: this.Education,
          address: this.Address,
          username: this.Username,
          // password: this.Password,
          jobdesk: this.Jobdesc,
          area_id: this.selectedArea.map(e => e.value),
          email: this.Email,
          foto: this.fileName,
          doc: this.docName,
          join_date: dayjs(this.JoinDate).format('YYYY-MM-DD'),
          role: this.selectedRole.value,
          status: this.Status === true ? 'ACTIVE' : 'INACTIVE',
          jabatan: this.Position,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error.response.data.error.message,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
      this.getUserDetail()
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.row {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 5px;
}
.nopad {
  padding: 0;
}
.labelfull {
  .custom-checkbox {
    width: 100%;
  }
}
.labelfull .custom-checkbox {
  width: 100%;
}
.tanggapan2 {
  display: flex;
  align-items: center;
  justify-content: end;
  .avatar {
    width: 80px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
  h4 {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  .input {
    margin-left: 25px;
    flex-grow: 1;
  }
  .input-group-append {
    cursor: pointer;
  }
}

.photo {
  span {
    margin-bottom: 10px;
  }
  div {
    border-radius: 10px;
    max-width: 350px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
}

.link {
  margin-top: 10px;
  display: block;
  color: #23425e;
  cursor: pointer;
  font-weight: bold;
}
</style>
