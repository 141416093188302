var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card-code',{attrs:{"title":"Form Add User"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('b-form-input',{attrs:{"id":"Name","placeholder":"Input Name"},model:{value:(_vm.Name),callback:function ($$v) {_vm.Name=$$v},expression:"Name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"NIK","rules":"required||numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"NIK","label-for":"NIK"}},[_c('b-form-input',{attrs:{"id":"NIK","placeholder":"Input NIK"},model:{value:(_vm.NIK),callback:function ($$v) {_vm.NIK=$$v},expression:"NIK"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Address","label-for":"Address"}},[_c('b-form-input',{attrs:{"id":"Address","placeholder":"Input Address"},model:{value:(_vm.Address),callback:function ($$v) {_vm.Address=$$v},expression:"Address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Latest Education","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Latest Education","label-for":"Latest Education"}},[_c('b-form-input',{attrs:{"id":"Latest Education","placeholder":"Input Latest Education"},model:{value:(_vm.Education),callback:function ($$v) {_vm.Education=$$v},expression:"Education"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Role","label-for":"Role"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Select Role","options":_vm.optionsKeputusan},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Join Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Join Date","label-for":"Join Date"}},[_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"date-datepicker"},model:{value:(_vm.JoinDate),callback:function ($$v) {_vm.JoinDate=$$v},expression:"JoinDate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Jobdesc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jobdesc","label-for":"Jobdesc"}},[_c('b-form-input',{attrs:{"id":"Jobdesc","placeholder":"Input Jobdesc"},model:{value:(_vm.Jobdesc),callback:function ($$v) {_vm.Jobdesc=$$v},expression:"Jobdesc"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Position","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Position","label-for":"Position"}},[_c('b-form-input',{attrs:{"id":"Position","placeholder":"Input Position"},model:{value:(_vm.Position),callback:function ($$v) {_vm.Position=$$v},expression:"Position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(!_vm.togglePhoto)?_c('div',{staticClass:"photo mt-2"},[_c('span',[_vm._v(" Photo ")]),_c('div',[_c('img',{attrs:{"src":("https://api.arginipratamaputra.com" + _vm.Photo)}})]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'detailUser' && _vm.isAdmin),expression:"this.$route.name === 'detailUser' && isAdmin"}],staticClass:"link",on:{"click":function($event){_vm.togglePhoto = !_vm.togglePhoto}}},[_vm._v(" Change Photo ")])]):_c('div',[_c('b-form-group',{attrs:{"label":"Photo","label-for":"Photo"}},[_c('b-form-file',{ref:"file",attrs:{"id":"Photo","type":"file","placeholder":"Input Photo","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'detailUser'),expression:"this.$route.name === 'detailUser'"}],staticClass:"link",on:{"click":function($event){_vm.togglePhoto = !_vm.togglePhoto}}},[_vm._v(" View Photo ")])],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{staticClass:"mb-2",attrs:{"name":"Area","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Area","label-for":"Area"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","multiple":"","placeholder":"Select Area","options":_vm.optionsArea},model:{value:(_vm.selectedArea),callback:function ($$v) {_vm.selectedArea=$$v},expression:"selectedArea"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(!_vm.toggleDoc)?_c('div',{staticClass:"photo"},[_c('span',[_vm._v(" Document ")]),_c('br'),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'detailUser'),expression:"$route.name === 'detailUser'"}],staticClass:"bg-gradient-primary mt-1",attrs:{"variant":"outline-primary","type":"submit","size":"sm"},on:{"click":function($event){return _vm.go(("https://api.arginipratamaputra.com" + _vm.docName))}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" Download ")])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'detailUser' && _vm.isAdmin),expression:"this.$route.name === 'detailUser' && isAdmin"}],staticClass:"link",on:{"click":function($event){_vm.toggleDoc = !_vm.toggleDoc}}},[_vm._v(" Change Document ")])],1):_c('div',[_c('b-form-group',{attrs:{"label":"Document","label-for":"Document"}},[_c('b-form-file',{ref:"doc",attrs:{"id":"Document","type":"file","placeholder":"Input File Document","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChangeDoc}})],1),_c('span',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'detailUser'),expression:"this.$route.name === 'detailUser'"}],staticClass:"link",on:{"click":function($event){_vm.toggleDoc = !_vm.toggleDoc}}},[_vm._v(" View Document ")])],1),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(" Status ")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.Status),callback:function ($$v) {_vm.Status=$$v},expression:"Status"}},[_vm._v(" Inactice/Active ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-card-code',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdmin),expression:"isAdmin"}],attrs:{"title":"Login Information"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required||email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('b-form-input',{attrs:{"id":"Email","placeholder":"Input Email"},model:{value:(_vm.Email),callback:function ($$v) {_vm.Email=$$v},expression:"Email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Username","label-for":"Username"}},[_c('b-form-input',{attrs:{"id":"Username","placeholder":"Input Username"},model:{value:(_vm.Username),callback:function ($$v) {_vm.Username=$$v},expression:"Username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name !== 'detailUser'),expression:"this.$route.name !== 'detailUser'"}],staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Password","label-for":"Password"}},[_c('b-form-input',{attrs:{"id":"Password","placeholder":"Input Password"},model:{value:(_vm.Password),callback:function ($$v) {_vm.Password=$$v},expression:"Password"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'detailUser'),expression:"$route.name === 'detailUser'"}],staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit","disabled":_vm.invalid},on:{"click":function($event){$event.preventDefault();return _vm.validationEdit($event)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" Save ")])]),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name !== 'detailUser'),expression:"$route.name !== 'detailUser'"}],staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('span',{staticClass:"align-middle"},[_vm._v(" Add ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }